import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import FlexSearch from 'flexsearch';

export const SearchContext = React.createContext({
    query: ``,
    searchResults: [] as SearchResult[],
    error: false,
    searchValid: false,
    searchIndex: undefined,
    searchStore: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSearch: (query: string) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadSearch: () => {},
    categorizedResults: [] as CategorizedResult[],
    popularSearchTerms: [] as string[],
});

export interface SearchResult {
    title: string;
    url: string;
    date: Date;
    type: string;
    id: number;
    externalLink?: boolean;
}

export interface CategorizedResult {
    heading: string;
    pages: {
        title: string;
        url: string;
        date: Date;
        externalLink?: boolean;
    }[];
}

export const SearchContextProvider = ({ children }) => {
    const MIN_SEARCH_LENGTH = 3;

    const data = useStaticQuery(graphql`
        query {
            localSearchPages {
                publicIndexURL
                publicStoreURL
            }
            allTaxonomyTermPopularSearchTerms {
                nodes {
                    name
                }
            }
        }
    `);

    const popularSearchTerms =
        data.allTaxonomyTermPopularSearchTerms?.nodes?.map((n) => n.name);

    const [query, setQuery] = useState(``);
    const [searchError, setSearchError] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const [searchIndex, setSearchIndex] = useState(null);
    const [searchStore, setSearchStore] = useState(null);

    const [categorizedResults, setCategorizedResults] = useState<
        CategorizedResult[]
    >([]);

    const flexsearch = useFlexSearch(query, searchIndex, searchStore);

    const handleSearch = (text: string) => {
        setQuery(text);
    };

    useEffect(() => {
        if (
            !searchIndex ||
            !searchStore ||
            !query ||
            query.trim().length < MIN_SEARCH_LENGTH ||
            searchError
        ) {
            setSearchValid(false);
            setSearchResults([]);
            return;
        }
        setSearchValid(true);
        setSearchResults(flexsearch);
    }, [query, searchIndex, searchStore, searchError]);

    useEffect(() => {
        const pages: CategorizedResult = {
            heading: `Pages`,
            pages: [],
        };

        const articles: CategorizedResult = {
            heading: `Articles`,
            pages: [],
        };

        const caseStudies: CategorizedResult = {
            heading: `Case Studies`,
            pages: [],
        };

        const markets: CategorizedResult = {
            heading: `Markets`,
            pages: [],
        };

        for (const result of searchResults.filter(
            (result) => !result.externalLink
        )) {
            let target = pages;
            if (
                result.type === `node__article` ||
                result.type === `node__news_press_article`
            ) {
                target = articles;
            } else if (result.type === `node__case_study_page`) {
                target = caseStudies;
            } else if (result.type === `node__city_page`) {
                target = markets;
            }

            target.pages.push({
                title: result.title,
                url: result.url,
                date: new Date(result.date),
                externalLink: result.externalLink,
            });
        }

        const newCategorizedResults: CategorizedResult[] = [];
        if (pages.pages.length) {
            newCategorizedResults.push(pages);
        }
        if (articles.pages.length) {
            newCategorizedResults.push(articles);
        }
        if (caseStudies.pages.length) {
            newCategorizedResults.push(caseStudies);
        }
        if (markets.pages.length) {
            newCategorizedResults.push(markets);
        }

        for (const item of newCategorizedResults) {
            item.pages.sort((a, b) => b.date.valueOf() - a.date.valueOf());
        }

        setCategorizedResults(newCategorizedResults);
    }, [searchResults]);

    const loadSearch = async () => {
        if (searchIndex && searchStore) return;
        try {
            const indexUrl = data.localSearchPages.publicIndexURL;
            const publicStoreURL = data.localSearchPages.publicStoreURL;
            const result = await Promise.all([
                axios.get(indexUrl),
                axios.get(publicStoreURL),
            ]);

            setSearchIndex(result[0].data);
            setSearchStore(result[1].data);
            handleSearch(query);
        } catch (e) {
            console.error(e);
            setSearchError(true);
        }
    };

    return (
        <SearchContext.Provider
            value={{
                query,
                handleSearch,
                loadSearch,
                error: searchError,
                searchIndex,
                searchStore,
                searchResults,
                searchValid,
                categorizedResults,
                popularSearchTerms,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => useContext(SearchContext);

export const useFlexSearch = (query, providedIndex, store, searchOptions?) => {
    const [index, setIndex] = useState(null);

    useEffect(() => {
        if (!providedIndex) {
            setIndex(null);
            return;
        }

        if (providedIndex instanceof FlexSearch) {
            setIndex(providedIndex);
            return;
        }

        const importedIndex = FlexSearch.create();

        (importedIndex.import as any)(providedIndex, { serialize: false });

        setIndex(importedIndex);
    }, [providedIndex]);

    return useMemo(() => {
        if (query === undefined || query === null || !index || !store)
            return [];

        const rawResults = index.search(query, searchOptions);

        return rawResults.map((id) => store[id]);
    }, [query, index, store]);
};
