module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SpectrumReach.com","short_name":"SpectrumReach","start_url":"/","background_color":"#ffff","display":"browser","icon":"src/assets/favicon-512x512.png","icons":[{"src":"/favicon.ico","sizes":"32x32","type":"image/ico"},{"src":"/icons/icon-48x48.png","sizes":"48x48"},{"src":"/icons/icon-72x72.png","sizes":"72x72"},{"src":"/icons/icon-96x96.png","sizes":"96x96"},{"src":"/icons/icon-144x144.png","sizes":"144x144"},{"src":"/icons/icon-192x192.png","sizes":"192x192"},{"src":"/icons/icon-256x256.png","sizes":"256x256"},{"src":"/icons/icon-384x384.png","sizes":"384x384"},{"src":"/icons/icon-512x512.png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b2029ac384462042c0d6c0a808af05b9"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
