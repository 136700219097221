exports.components = {
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-your-solution-tsx": () => import("./../../../src/pages/your-solution.tsx" /* webpackChunkName: "component---src-pages-your-solution-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/Article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-case-study-case-study-tsx": () => import("./../../../src/templates/CaseStudy/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-case-study-tsx" */),
  "component---src-templates-landing-page-landing-page-tsx": () => import("./../../../src/templates/LandingPage/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-tsx" */),
  "component---src-templates-marketing-map-marketing-map-tsx": () => import("./../../../src/templates/MarketingMap/MarketingMap.tsx" /* webpackChunkName: "component---src-templates-marketing-map-marketing-map-tsx" */),
  "component---src-templates-news-press-article-news-press-article-tsx": () => import("./../../../src/templates/NewsPressArticle/NewsPressArticle.tsx" /* webpackChunkName: "component---src-templates-news-press-article-news-press-article-tsx" */)
}

